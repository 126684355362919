import React from "react"
import HeroBanner from "../components/HeroBanner"

const Success = props => {
  return (
    <HeroBanner
      title="Thank you for your message!"
      subTitle="I will reply to your enquiry within 1 working day "
    />
  )
}

export default Success
