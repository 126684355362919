import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledContainer = styled.div`
  margin: 0 auto;
  max-width: 900px;
  padding: 0px 24px;
`

const Container = props => {
  return <StyledContainer style={props.style}>{props.children}</StyledContainer>
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
}

export default Container
